// extracted by mini-css-extract-plugin
export var active = "LogoProcessSteps-module--active--b9b9e";
export var left = "LogoProcessSteps-module--left--b2260";
export var process_steps = "LogoProcessSteps-module--process_steps--9a695";
export var process_steps_container = "LogoProcessSteps-module--process_steps_container--b174e";
export var right = "LogoProcessSteps-module--right--7d655";
export var step = "LogoProcessSteps-module--step--55e82";
export var step_1 = "LogoProcessSteps-module--step_1--d5f0c";
export var step_2 = "LogoProcessSteps-module--step_2--faa46";
export var step_3 = "LogoProcessSteps-module--step_3--9715c";
export var step_4 = "LogoProcessSteps-module--step_4--83721";
export var step_5 = "LogoProcessSteps-module--step_5--36ac6";
export var step_6 = "LogoProcessSteps-module--step_6--5ab4e";
export var step_brief = "LogoProcessSteps-module--step_brief--dbd86";
export var step_content = "LogoProcessSteps-module--step_content--d0ee7";
export var step_empty = "LogoProcessSteps-module--step_empty--098c9";
export var step_info = "LogoProcessSteps-module--step_info--6e348";
export var step_kodowanie = "LogoProcessSteps-module--step_kodowanie--52001";
export var step_number = "LogoProcessSteps-module--step_number--74d12";
export var step_number_last = "LogoProcessSteps-module--step_number_last--f84be";
export var step_number_left = "LogoProcessSteps-module--step_number_left--dc5d7";
export var step_projektowanie = "LogoProcessSteps-module--step_projektowanie--d308c";
export var step_testowanie = "LogoProcessSteps-module--step_testowanie--6991b";
export var step_wdrozenie = "LogoProcessSteps-module--step_wdrozenie--40387";