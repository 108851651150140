import React, { useEffect, useState } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import * as styles from './LogoProcessSteps.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';

const LogoProcessSteps = () => {
  const [activeStep, setActiveStep] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const stepElements = document.querySelectorAll(`.${styles.step}`);
      const windowHeight = window.innerHeight;

      stepElements.forEach((step, index) => {
        const rect = step.getBoundingClientRect();
        const elementMiddle = rect.top + rect.height / 2;

        if (elementMiddle < windowHeight * 0.75 && elementMiddle > windowHeight * 0.25) {
          setActiveStep(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className={styles.process_steps_container}>
      <Container>
        <SectionTitle title="My Design Process" coloredText=" Logo" />
        <div className={styles.process_steps}>
          <div className={`${styles.step} ${styles.left} ${activeStep === 0 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_1} ${styles.step_brief}`}>
              <h3>Brief and Consultation</h3>
              <p>
                We start with a discussion about your vision. I gather information about your business, goals, target
                audience, and aesthetic preferences. This stage helps me understand what kind of logo best reflects your
                brand’s character.
              </p>
            </div>
            <div className={styles.step_number}>
              <StaticImage
                src="../../images/brief.png"
                alt="icon representing the initial client contact to gather information about the needed logo"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.right} ${activeStep === 1 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_2} ${styles.step_info}`}>
              <h3>Concept Creation</h3>
              <p>
                Based on the brief, I create 3-5 unique logo concepts that align with your requirements and vision. Each
                design is carefully crafted to suit the industry in which your business operates.
              </p>
            </div>
            <div className={styles.step_number_left}>
              <StaticImage
                src="../../images/kontent.png"
                alt="icon representing the initial work on creating the logo"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.left} ${activeStep === 2 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_3} ${styles.step_projektowanie}`}>
              <h3>Selection and Revisions</h3>
              <p>
                You choose the concept that resonates most with you. I then make any necessary revisions based on your
                feedback until the logo fully meets your expectations.
              </p>
            </div>
            <div className={styles.step_number}>
              <StaticImage
                src="../../images/projektowanie.png"
                alt="icon representing the actual logo design"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.right} ${activeStep === 3 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_4} ${styles.step_kodowanie}`}>
              <h3>Finalization and File Delivery</h3>
              <p>
                Upon approval, I deliver the final logo in various formats, ready for use on websites, printed
                materials, and other media (PNG, SVG, PDF). You'll receive the logo in both color and monochrome
                versions.
              </p>
            </div>
            <div className={styles.step_number_left}>
              <StaticImage
                src="../../images/tworzenie-strony.png"
                alt="icon representing the final version of the logo"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.left} ${activeStep === 4 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_5} ${styles.step_wdrozenie}`}>
              <h3>Support</h3>
              <p>
                After completing the project, I offer additional support if you need assistance implementing the logo on
                your website, social media, or printed materials.
              </p>
            </div>
            <div className={styles.step_number_last}>
              <StaticImage src="../../images/koniec.png" alt="icon representing post-project support" width={150} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LogoProcessSteps;
